<template>
  <div class="page page-xprogrammatic-all-campaigns">
    <PageHeader
    :icon="$t('CAMPAIGN.ICON')"
    :title="$t('CAMPAIGN.TITLES')"
    >

    <template slot="nav">
      <small><strong>{{$tc('CAMPAIGN.TITLE_COUNT',count)}}</strong></small>
    </template>

      <!-- <v-menu
        offset-y
        nudge-bottom="5px"
        :close-on-click="true"
      >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on: onTip, attrs: attrsTip }">
              <v-btn
                fab
                small
                depressed
                color="primary"
                v-bind="{...attrsMenu, ...attrsTip}"
                v-on="{...onMenu, ...onTip}"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.MORE_ACTIONS') }}
          </v-tooltip>
        </template>

        <v-list nav dense>
          <v-list-item link @click="newItem">
            <v-list-item-icon><v-icon size="20">mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">
                <strong>{{ $t('COMMON.NEW') }}</strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="success"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="newItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.ADD') }}
      </v-tooltip>
    </PageHeader>

    <FormCampaignStepper
      :show="showNew"
      @close="showNew = false"
      @create="goToItem"
    />

    <Side
      component="forms/campaign/compact/FormCampaignCompact"
      :ids="(selected || []).map(v => v.id)"
      :value="showEdit"
      @close="closeEdit"
      @create="goToItem"
      @update="loadItems"
    />
    <!-- <Dialog
      :icon="selected.length ? 'mdi-pencil' : 'mdi-plus-thick'"
      :title="$t('COMMON.EDIT') + ': ' + $tc('USER_PERMISSION.TITLE_COUNT', selected.length)"
      component="form/FormAssignUserPermission"
      :items="selected || []"
      :props="{type:'campaign'}"
      :value="showDialogPermissionEdit"
      @close="showDialogPermissionEdit = false"
      @update="loadItems"
      @select="selectItems"
    /> -->
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="title"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />
    <List
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :pagination="true"
      :loading="loading"
      :selected="selected"
      :selectable="false"
      mode="col"
      cols="6"
      md="4"
      lg="3"
      xl="2"
      @applyFilters="applyFilters"
      @resetFilters="resetFilters"
      @dblclick="goToItem"
      @select="selectItems"
      @page="changePage"
      @reload="searchItems"
    >
      <template v-slot:header="">
        <div class="col-4">
          <v-text-field
            rounded
            ref="where.title"
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </div>
      </template>

      <template v-slot:filters="">
        <div>
          <p class="mb-0 primary--text">{{ $t('INPUT.STATUS') }}</p>
          <v-select
            class="none"
            v-model="status"
            multiple
            :items="statuses"
            item-text="text"
            item-value="value"
            ref="where.status"
          />
          <template v-for="(item,i) in statuses">
            <v-checkbox
              v-model="status"
              :key="'sta'+i"
              :label="item.text"
              :value="item.value"
              hide-details
              class="mt-0"
            />
          </template>
        </div>

        <div class="mt-6">
          <p class="mb-0 primary--text">{{ $t('INPUT.FILEFORMAT') }}</p>
          <v-select
            class="none"
            v-model="fileformat"
            multiple
            :items="formats"
            item-text="text"
            item-value="value"
            ref="where.fileformat"
          />
          <template v-for="(item,i) in formats">
            <v-checkbox
              v-model="fileformat"
              :key="'fila'+i"
              :label="item.text"
              :value="item.value"
              hide-details
              class="mt-0"
            />
          </template>
        </div>

      </template>

      <template v-slot:empty="">
        <div class="ml-10 mt-6 text-center">
          <div class="text-h5 mt-10 mb-4">{{$t('LIST.NO_RESULT')}}</div>
          <template v-if="$queryFilters().count() > 0">
            <div><small class="grey--text">{{$t('LIST.SUGGESTION')}}</small></div>
            <div><a class="info--text" @click="clearFilters">{{$t('LIST.CLEAR_FILTER')}}</a></div>
            <!-- <div><a class="info--text" @click="newItem">{{$t('LIST.CREATE_ITEM')}}</a></div> -->
          </template>
        </div>
      </template>

      <template v-slot:item-col="item">

          <v-card color="elevation-0" :ripple="true" :class="['cursor-pointer', Date.now() - +new Date(item.updated_at) < 5000 ? 'highlight' : '']">
            <v-system-bar dark color="primary pa-4 pl-2">
              <v-icon class="mr-2" size="20">{{$t('CAMPAIGN.ICON')}}</v-icon>
              <strong class="text-large text-ellipsis">{{item.title}}</strong>
              <v-spacer/>
            </v-system-bar>
            <div :class="[item.status_type, 'lighten-5 pa-4 pt-2 pb-2']">
              <v-icon :color="item.status_type" class="mr-2">{{$t(item.status_code + '_ICON')}}</v-icon>
              <span>{{$t(item.status_code)}}</span>
            </div>

            <div class="pr-4 pl-4">
              <v-list class="list-small">
                <v-list-item>
                  <v-list-item-content><v-list-item-title>{{$t('PUBLICATION.TITLES')}}</v-list-item-title></v-list-item-content>
                  <div><strong class="number">{{(+item.publication_count).toLocaleString()}}</strong></div>
                </v-list-item>
                <v-divider color="black" />
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.PUBLISHED')}}</small></v-list-item-title>
                  </v-list-item-content>
                  <div><strong :class="['number', item.publication_count_published > 0 ? 'success--text' : 'grey--text']">{{(+item.publication_count_published).toLocaleString()}}</strong></div>
                </v-list-item>
                <!-- <v-divider /> -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.UNPUBLISHED')}}</small></v-list-item-title>
                  </v-list-item-content>
                  <div><strong :class="['number', item.publication_count_unpublished > 0 ? 'finished--text' : 'grey--text']">{{(+item.publication_count_unpublished).toLocaleString()}}</strong></div>
                </v-list-item>
                <!-- <v-divider /> -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.REFRESH_WAIT')}}</small></v-list-item-title>
                  </v-list-item-content>
                  <div><strong :class="['number', item.publication_count_waiting > 0 ? 'info--text' : 'grey--text']">{{(+item.publication_count_waiting).toLocaleString()}}</strong></div>
                </v-list-item>
                <!-- <v-divider /> -->
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.DISPATCH_FAILED')}}</small></v-list-item-title>
                  </v-list-item-content>
                  <div><strong :class="['number', item.publication_count_error > 0 ? 'error--text' : 'grey--text']">{{(+item.publication_count_error).toLocaleString()}}</strong></div>
                </v-list-item>
              </v-list>
            </div>
            <v-divider />
            <v-card-actions class="pa-4">
              <v-btn icon @click.stop="removeItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              <!-- <v-btn icon @click.stop="pauseItem(item)" v-if="!item.paused"><v-icon>mdi-pause</v-icon></v-btn> -->
              <!-- <v-btn icon @click.stop="playItem(item)" v-else><v-icon>mdi-play</v-icon></v-btn> -->
              <v-btn
                icon
                @click.stop="editItem(item)"
                class="ml-4"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-spacer />

              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    depressed
                    dark
                    color="indigo"
                    v-bind="attrs"
                    v-on="on"
                    @click="goToItem(item)"
                  >
                    <v-icon>{{$t('PUBLICATION.ICONS')}}</v-icon>
                  </v-btn>
                </template>
                {{ $t('CAMPAIGN.SEE_PUBLICATION') }}
              </v-tooltip>

            </v-card-actions>
            <!-- <v-divider />
            <div class="text-small pa-2 pl-6">
              {{ $t("CHANNEL_SUBSCRIPTION.LAST_UPDATED") }}
              <br />
              <strong>{{ new Date(item.updated_at).toLocaleString() }}</strong>
            </div> -->
          </v-card>

      </template>

    </List>
  </div>
</template>


<script>

import PageHeader from '@/components/PageHeader'
// import Dialog from '@/components/Dialog'
// import StatusLabel from '@/components/StatusLabel'
import Side from '@/components/Side'
import DialogRemove from '@/components/DialogRemove'
import List from '@/components/List'
import FormCampaignStepper from '@/forms/campaign/stepper/FormCampaignStepper'

const ELEMENT_NAME = 'campaign'
const TABLE_NAME = 'campaigns'
const SERVICE_NAME = 'api_programmatic'

const _formats = [ 'xml', 'csv', 'json' ]
const _statuses = [ 1, 0, -1 ]

export default {
  name: 'PageXprogrammaticAllCampaigns',
  components: {
    FormCampaignStepper,
    PageHeader,
    // Dialog,
    Side,
    DialogRemove,
    List,
    // StatusLabel
  },
  data: () => ({
    loading: false,
    ids: [],
    items: [],
    count: 0,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    showEdit: false,
    showNew: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [1],
    fileformat: [],
    methods: [],
    startOfDay: new Date(`${new Date().toISOString().substr(0,10)  }T00:00:00.000Z`),
    endOfDay: new Date(`${new Date().toISOString().substr(0,10)  }T23:59:59.999Z`),
  }),
  props: {
    update:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
    select:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
  },

  watch: {
    update () {
      this.loadItems()
    },
    select (items) {
      this.selected = items
    }
  },

  mounted () {
    this.$queryFilters().get()
    this.searchItems()
    if (this.itemId) {
      this.selectItems([{id: this.itemId}])
      this.editSelected()
    }
  },

  computed: {

    itemId () {
      return this.$router.currentRoute.query.edit || undefined
    },

    statuses () {
      return _statuses.map(value => ({
        value, text: this.$t(`INPUT.STATUS_${value === -1 ? '_1' : value}`),
      }))
    },

    formats () {
      return _formats.map(value => ({
        value,
        text: this.$t(`INPUT.FILEFORMAT_${value.toUpperCase()}`),
      }))
    },
  },

  methods: {

    clearFilters () {
      this.$queryFilters().clear()
      this.loadItems()
    },

    pauseItem () {
      console.warning('pauseItem TODO')
    },

    playItem () {
      console.warning('playItem TODO')
    },

    statusReportValue(name, item) {
      if (item.status_report && item.status_report[name]) {
        return Number(item.status_report[name]).toLocaleString()
      }
      return 0
    },

    // Request

    // search with a timeout
    searchItems(timeout = 150) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, timeout)
    },

    // load items with filters
    loadItems (options = {}) {
      this.loading = true
      this.$queryFilters().save()

      // build query
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.page || 1,
        limit: this.limit,

      }
      const query = this.$queryFormat({
        options: Object.assign({}, defaultOptions, options),
        where: {
          publication_count: {
            status: 1
          },
          status: [1],
        },
      })

      const handleResponse = (response) => {
        if (response.data) {
          this.items = response.data.items
          this.count = response.data.count
          this.offset = response.data.offset
          this.limit = response.data.limit
        } else {
          this.items = []
          this.count = 0
          this.offset = 0
          this.limit = 0
        }
        this.updateSelected()
      }

      // request
      this.$services[SERVICE_NAME][ELEMENT_NAME]
        .search(query)
        .then(response => {
          handleResponse(response)
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    confirmRemoveItems () {
      const ids = this.selected.map(v => v.id)
      this.$services.api_programmatic.campaign.remove({ where: { id: ids }, options: {limit:ids.length}})
        .then(response => {
          if (response.data) {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          }
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    updateSelected () {
      for (let i = 0, lenI = this.selected.length; i < lenI; i++) {
        const a = this.selected[i]
        for (let j = 0, lenJ = this.items.length; j < lenJ; j++) {
          const b = this.items[j]
          if (a.id === b.id) {
            this.selected[i] = b
            break
          }
        }
      }
    },

    // Mutliple items

    removeSelected () {
      this.showDialogRemove = true
    },

    editSelected () {
      this.showEdit = true
      if (this.selected.length > 0) {
        const item = this.selected[0]
        this.$router.push(`/xprogrammatic/campaigns/?edit=${  this.selected[0].id}`)
      }
    },

    selectItems (arr) {
      this.selected = arr
    },

    closeEdit () {
      this.showEdit = false
      this.$router.push('/xprogrammatic/campaigns/')
    },

    // Single item

    updateItems () {
      this.loadItems()
    },

    newItem () {
      this.selected = []
      this.showNew = true
    },

    refreshItem (item) {
      this.loading = true
      this.$services.api_programmatic.campaign.refresh({where: {id:item.id}})
        .finally(() => {
          this.loading = false
          this.loadItems()
        })
    },

    editItem (item) {
      this.selected = [item]
      this.$router.push(`/xprogrammatic/campaigns/?edit=${this.selected[0].id}`)
      this.showEdit = true
    },

    editPermissionItem (item) {
      this.selected = [item]
      this.showDialogPermissionEdit = true
    },

    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    goToItem (item) {
      item = item ? item : this.selected[0]
      const route = `/xprogrammatic/campaign/${item.id}`
      this.$router.push(`/xprogrammatic/campaign/${item.id}`)
    },
    // filter methods
    resetFilters () {
      this.applyFilters()
    },

    applyFilters () {
      this.searchItems()
    },
  }
}
</script>
